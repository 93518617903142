<template>
    <div class="about">
        <div class="container">
            <div class="title">
                <span>关于我们</span>
            </div>
            <div class="content">
                {{ beianInfo.company }}是一家以互联网为核心的教育公司，专职于做专业化、快速培训人才的英语学堂。
            </div>
            <img class="official-qrcode" src="@/assets/images/official-qrcode.png" />
            <div class="desc">更多资讯欢迎关注雪饼英语公众号</div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    computed: { ...mapGetters(['beianList']) },
    data() {
        return {
            beianInfo: null,
        }
    },
    created() {
        this.beianInfo = this.beianList[0]
    },
    mounted() {
        this.beianList.forEach((item) => {
            if (location.host.indexOf(item.host) > -1) {
                this.beianInfo = item
            }
        })
    },
}
</script>
<style lang="scss" scoped>
.about {
    width: 100%;
    background-image: url('https://res.peki.vip/20221226/c3e15d726ad34e8398c192172dd643e0.png');
    background-size: cover;
    .container {
        width: 100%;
        height: 100%;
    }
    .title {
        padding: 40px 0 32px;
        text-align: center;
        span {
            color: #000;
            font-size: 24px;
            font-weight: 700;
            position: relative;
            &::after {
                position: absolute;
                right: -36px;
                top: 50%;
                content: '';
                transform: translateY(-50%);
                width: 20px;
                height: 2px;
                background: #000;
            }
            &::before {
                position: absolute;
                left: -36px;
                top: 50%;
                transform: translateY(-50%);
                content: '';
                width: 20px;
                height: 2px;
                background: #000;
            }
        }
    }
    .content {
        color: #000;
        font-size: 16px;
        width: 360px;
        text-align: center;
        margin: 0 auto;
        line-height: 1.6em;
    }
    .official-qrcode {
        width: 160px;
        height: 160px;
        background: #ffffff;
        border-radius: 8px;
        padding: 8px;
        margin: 20px auto 0;
        display: block;
        box-sizing: border-box;
    }
    .desc {
        color: #000;
        font-size: 14px;
        padding: 20px;
        text-align: center;
    }
}

@media screen and (max-width: 640px) {
    .about {
        .title {
            padding: 28px 0 18px;
            span {
                font-size: 16px;
            }
        }
        .content {
            font-size: 14px;
            width: 240px;
        }
        .official-qrcode {
            width: 120px;
            height: 120px;
            border-radius: 8px;
            padding: 4px;
            margin: 12px auto 0;
        }
        .desc {
            font-size: 12px;
            padding: 12px;
        }
    }
}
</style>
