<template>
    <div class="product" v-if="productList.length !== 0">
        <div class="teacher-content">
            <CustomTitle
                titleName="CATTI课程介绍"
                class="title-component"
                title="程介绍"
                head="课"
                subtitle="CURRICULUM"
            />
        </div>
        <div class="product-list">
            <div class="product-item" v-for="item in productList" :key="item.id" @click="toProductDetailsPage(item)">
                <img class="cover" v-lazy="item.bannerImageUrl" />
                <div class="title ov-ellipsis">{{ item.title }}</div>
                <div class="desc">
                    {{ item.sellExtra.intro }}
                </div>
                <div class="other">
                    <div class="lesson-cnt">{{ item.sellExtra.lessonCnt || 0 }}课时</div>
                </div>
            </div>
        </div>
        <a class="more-btn" target="_blank" href="/product">查看更多课程</a>
    </div>
</template>
<script>
import CustomTitle from '@/components/CustomTitle'

export default {
    components: { CustomTitle },
    props: ['productList'],
    methods: {
        toProductDetailsPage(item) {
            window.open(`/productDetails/${item.packageNo}`, '_blank')
        },
    },
}
</script>
<style lang="scss" scoped>
.product {
    max-width: $center-width;
    margin: 36px auto 0;
    padding: 0 16px;
    position: relative;
}
.product-list {
    display: flex;
    justify-content: space-around;
}
.product-item {
    margin: 0 9px 24px;
    width: 300px;
    height: 320px;
    background: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0px 0px 6px 0px rgba(51, 49, 47, 0.16);
    &:hover {
        transition: all 0.2s;
        transform: translateY(-8px);
        box-shadow: 0px 0px 18px 0px rgba(51, 49, 47, 0.3);
    }
    .cover {
        width: 300px;
        height: 174px;
        display: block;
    }
    .title {
        font-size: 16px;
        color: #33312f;
        line-height: 16px;
        margin: 20px 0 12px;
        font-weight: 700;
        width: 96%;
        &::before {
            content: '';
            width: 4px;
            height: 16px;
            background: $theme-color;
            display: inline-block;
            margin-right: 12px;
            vertical-align: -2px;
        }
    }
    .desc {
        height: 54px;
        font-size: 12px;
        color: #66625e;
        line-height: 1.6em;
        min-height: 24px;
        margin: 0 16px;
        @include ov-ellipsiss(3);
    }
    .other {
        margin: 10px 16px 0;
        display: flex;
        justify-content: space-between;
        .lesson-cnt {
            font-weight: 700;
            font-size: 16px;
            color: $theme-color;
            line-height: 22px;
        }
    }
}

.more-btn {
    width: 180px;
    height: 42px;
    border-radius: 25px;
    border: 1px solid $theme-color;
    font-size: 14px;
    color: $theme-color;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px auto 30px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
        background: $theme-color;
    }
}

@media screen and (max-width: 960) and (min-width: 720px) {
    .title-component {
        text-align: center;
    }
    .teacher-content {
        .right-icon,
        .left-icon {
            display: none;
        }
    }
}
@media screen and (max-width: 719px) and (min-width: 520px) {
    .title-component {
        text-align: center;
    }
}
@media screen and (max-width: 519px) {
    .title-component {
        text-align: center;
    }
}
</style>
