<template>
    <div class="teacher">
        <div class="teacher-content">
            <CustomTitle
                titleName="CATTI师资力量"
                class="title-component"
                title="资力量"
                head="师"
                subtitle="TEACHER"
            />
        </div>
        <div class="teacher-content" @mouseover="teacherSwipePlay = 0" @mouseout="teacherSwipePlay = 2000">
            <div class="left-icon" @click="onTeacherSwiperChange('prev')">
                <van-icon name="arrow-left" />
            </div>
            <van-swipe ref="teacherSwiper" :autoplay="teacherSwipePlay" indicator-color="#f0941d">
                <van-swipe-item v-for="(item, index) in teacherList" :key="index">
                    <img :src="item.cover" />
                    <div>
                        <div class="name-desc">
                            <div class="name">{{ item.name }}</div>
                            <div class="desc">
                                <span>{{ item.desc }}</span>
                            </div>
                        </div>
                        <div class="teacher-tags">
                            <div v-for="(tag, subIndex) in item.about" :key="subIndex" class="teacher-tag">
                                <span>※</span>&nbsp;{{ tag }}
                            </div>
                        </div>
                        <div v-if="item.teacherNo" class="teacher-info" @click="toTeacher(item.teacherNo)">
                            更多介绍
                        </div>
                    </div>
                </van-swipe-item>
            </van-swipe>
            <div class="right-icon" @click="onTeacherSwiperChange('next')">
                <van-icon name="arrow" />
            </div>
        </div>
    </div>
</template>
<script>
import CustomTitle from '@/components/CustomTitle'

const teacherList = [
    {
        nickname: 'Adrianna',
        name: '魏苏萌',
        desc: '讲师介绍',
        cover: 'https://res.peki.vip/20230106/158122dc86494a9a89cdcd6f4761c208.png',
        about: [
            '香港中文大学翻译硕士毕业',
            '曾任央视CGTN同声传译员',
            'CATTI（翻译从业资格）二级口译证书',
            '雅思8.0',
            '英语专业八级',
        ],
    },
    {
        nickname: 'Mengyan',
        name: '孟炎',
        teacherNo: 'mengyan',
        desc: '讲师介绍',
        cover: 'https://res.peki.vip/20210624/d0faa1bd89f241bdb9257faad181c01f.png',
        about: [
            '持有CATTI笔译证书',
            '密苏里大学哥伦比亚分校经济学硕士，全额奖学金',
            '曾任新东方教研组组长,在新东方工作6年',
            'ETS Propell Workshop认证教师',
            '全国大学生英语竞赛特等奖',
            '托福成绩116,多次听力口语写作满分',
        ],
    },
    {
        nickname: 'Zen',
        name: '韦震',
        teacherNo: 'weizhen',
        desc: '讲师介绍',
        cover: 'https://res.peki.vip/20210624/6a6d304043de495aaf479172d852fd57.png',
        about: [
            '爱尔兰前总理外交翻译',
            '翻译专家人才库储备人才',
            '爱尔兰都柏林理工大学讲师',
            '曾担任爱尔兰欧盟‘China Ready’项目讲师 ',
            '外研社国才考试高级三星培训师',
            '爱尔兰都柏林理工大学博士',
        ],
    },
]

export default {
    components: { CustomTitle },
    data() {
        return {
            teacherList: teacherList,
            teacherSwipePlay: 0,

            activeItem: teacherList[0],
        }
    },
    methods: {
        onTeacherSwiperChange(type) {
            if (this.$refs.teacherSwiper) {
                this.$refs.teacherSwiper[type]()
            }
        },
        toTeacher(no) {
            window.open(`/teacherDetails/${no}`)
        },
    },
}
</script>

<style lang="scss" scoped>
.teacher {
    background: #f7f7f7;
    padding: 40px 16px 60px;
}
.teacher-content {
    max-width: $center-width;
    margin: 0 auto;
    position: relative;
    .left-icon,
    .right-icon {
        position: absolute;
        z-index: 99;
        width: 32px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba($color: #000000, $alpha: 0.1);
        color: #fff;
        top: 168px;
        font-size: 32px;
    }
    .left-icon {
        left: 0;
        border-radius: 2px 0 0 2px;
    }
    .right-icon {
        right: 0;
        border-radius: 0 2px 2px 0;
    }
    ::v-deep .van-swipe-item {
        display: flex;
        position: relative;
        > img {
            width: 240px;
            height: 337px;
            border-radius: 8px;
            background: none;
            flex-shrink: 0;
            margin: 0 48px 10px 20px;
            position: relative;
            object-fit: contain;
            z-index: 2;
            pointer-events: none;
        }
        > div {
            .name-desc {
                display: flex;
            }
            .name {
                width: 182px;
                height: 73px;
                background: $theme-color;
                position: relative;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 34px;
                font-weight: 700;
                &::before {
                    position: absolute;
                    content: 'TEACHER';
                    top: 0;
                    left: 18px;
                    transform: rotate(90deg);
                    transform-origin: left;
                    font-size: 12px;
                    font-weight: 400;
                }
            }
            .desc {
                margin-left: 14px;
                color: #000;
                font-size: 16px;
                position: relative;
                height: 20px;
                align-self: flex-end;
                margin-bottom: 4px;
                span {
                    position: relative;
                    z-index: 1;
                }
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 3px;
                    left: 50%;
                    width: 80px;
                    height: 4px;
                    transform: translateX(-50%);
                    background: $theme-color;
                }
            }
        }
        .teacher-tags {
            margin-top: 32px;
            font-size: 18px;
            line-height: 2em;
            span {
                color: $theme-color;
                padding-right: 8px;
            }
        }
        .teacher-info {
            position: absolute;
            right: 20px;
            bottom: 20px;
            line-height: 2em;
            color: $theme-color;
            font-size: 14px;
            display: flex;
            align-items: center;
        }
        .van-icon-down {
            transform: rotate(-90deg);
            margin-left: 6px;
        }
    }
    ::v-deep .van-swipe__indicator {
        background: #333;
    }
}

@media screen and (max-width: 960) and (min-width: 720px) {
    .title-component {
        text-align: center;
    }
    .teacher-content {
        .right-icon,
        .left-icon {
            display: none;
        }
    }
}
@media screen and (max-width: 719px) and (min-width: 520px) {
    .title-component {
        text-align: center;
    }
    .teacher-content {
        .right-icon,
        .left-icon {
            display: none;
        }
        ::v-deep .van-swipe-item {
            justify-content: center;
            > img {
                width: 180px;
                height: 252px;
                border-radius: 4px;
                margin-right: 20px;
            }
            > div {
                .name {
                    width: 90px;
                    height: 36px;
                    font-size: 18px;
                    border-radius: 2px;
                    &::before {
                        transform: rotate(90deg) scale(0.4);
                        left: 8px;
                        top: 0;
                    }
                }
                .desc {
                    margin-left: 12px;
                    color: #000;
                    font-size: 14px;
                    height: 18px;
                    &::after {
                        width: 72px;
                    }
                }
            }
            .teacher-tags {
                margin-top: 16px;
                font-size: 14px;
                line-height: 2em;
            }
        }
    }
}
@media screen and (max-width: 519px) {
    .title-component {
        text-align: center;
    }
    .teacher-content {
        .right-icon,
        .left-icon {
            display: none;
        }
        ::v-deep .van-swipe-item {
            justify-content: center;
            position: relative;
            > img {
                width: 120px;
                height: 168px;
                border-radius: 4px;
                margin-right: 12px;
            }
            > div {
                .name {
                    width: 80px;
                    height: 32px;
                    font-size: 16px;
                    border-radius: 2px;
                    &::before {
                        transform: rotate(90deg) scale(0.3);
                        left: 8px;
                        top: 0;
                    }
                }
                .desc {
                    margin-left: 8px;
                    color: #000;
                    font-size: 14px;
                    height: 18px;
                    &::after {
                        width: 72px;
                    }
                }
            }
            .teacher-tags {
                margin-top: 12px;
                font-size: 12px;
                line-height: 1.6em;
            }
            .teacher-info {
                right: 10px;
                bottom: 10px;
                line-height: 1em;
                font-size: 12px;
            }
        }
    }
}
</style>
