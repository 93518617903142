<template>
    <div>
        <div class="banner">
            <img class="bg" v-if="bannerList.length !== 0" :src="bannerList[activeIndex].coverUrl" />
            <van-swipe
                ref="bannerSwipe"
                :autoplay="5000"
                class="swiper"
                indicator-color="white"
                :show-indicators="true"
                @change="onSwiperChange"
            >
                <van-swipe-item v-for="item in bannerList" :key="item.id">
                    <a v-if="item.action === 'WEB_URL'" target="_blank" :href="item.target">
                        <img :src="item.coverUrl" />
                    </a>
                    <nuxt-link v-else-if="item.action === 'ROUTE_URL'" :to="item.target">
                        <img :src="item.coverUrl" />
                    </nuxt-link>
                    <img v-else :src="item.coverUrl" />
                </van-swipe-item>
            </van-swipe>
        </div>
        <div class="banner-br"></div>
    </div>
</template>
<script>
import { getBannerConfig } from '@/api/indexApi.js'

export default {
    data() {
        return {
            bannerList: [],
            activeIndex: 0,

            modelType: '',
        }
    },
    mounted() {
        this.onResize()
        window.addEventListener('resize', this.onResize)
    },
    destroyed() {
        window.removeEventListener('resize', this.onResize)
    },
    methods: {
        onResize() {
            let type = 'website'
            if (document.documentElement.clientWidth <= 720) {
                type = 'website-h5'
            }
            if (type !== this.modelType) {
                this.getBannerConfigApi(type)
            }
        },
        onSwiperChange(val) {
            this.activeIndex = val
        },
        onSwitchChange(val) {
            this.$refs.bannerSwipe.swipeTo(val)
        },
        getBannerConfigApi(channel) {
            this.modelType = channel
            const positions = 'HOME_TOP_BANNER'
            getBannerConfig({ module: 'cake', positions, channel }).then((res) => {
                this.bannerList = res.data[positions]
                this.$refs.bannerSwipe.resize()
                this.activeIndex = 0
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.banner {
    padding: 42px 0;
    position: relative;
    .bg {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.6;
        width: 100%;
        height: 100%;
        filter: blur(16px);
        object-fit: cover;
    }
    .swiper {
        width: 80vw;
        height: 25.54vw;
        margin: 0 auto;
    }
}
.banner-br {
    width: 100%;
    height: 40px;
    position: relative;
    z-index: 9;
    display: flex;
    overflow: hidden;
    &::before {
        content: '';
        display: block;
        width: calc(100% - 120px);
        height: 100%;
        background: #ffffff;
    }
    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        background: #ffffff;
        height: 200%;
        width: 240px;
        border-radius: 50%;
    }
}

.van-swipe {
    img {
        pointer-events: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }
}

@media screen and (max-width: 1400px) and (min-width: 720px) {
    .banner {
        padding: 0;
        .bg {
            display: none;
        }
        .swiper {
            width: 100%;
            height: 31.94vw;
        }
    }
    .banner-br {
        display: none;
    }
}

@media screen and (max-width: 720px) {
    .banner {
        padding: 0;
        .bg {
            display: none;
        }
        .swiper {
            width: 100%;
            height: 44.75vw;
        }
    }
    .banner-br {
        display: none;
    }
}
</style>
